import React, { useContext, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Menu, Avatar, MenuItem, makeStyles, ListItemIcon, ListItemText, CircularProgress, IconButton } from '@material-ui/core';
import { mdiAccount, mdiLogout } from '@mdi/js';
import Icon from '@mdi/react';

import useAuth from 'hooks/useAuth';
import Context from 'setup/context';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    fontSize: 16,
    marginRight: theme.spacing(1),
    background: theme.palette.secondary.main
  },
  popover: {
    width: 200
  }
}));

const Account = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const {
    message: { handleError }
  } = useContext(Context);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      setSubmitting(true);
      await logout();
      handleClose();
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={handleOpen}>
        <Avatar bg="secondary" alt={user?.name} src={user?.avatar} color="secondary" className={classes.avatar} />
      </IconButton>
      <Menu
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        anchorEl={ref.current}
        PaperProps={{ className: classes.popover }}
        keepMounted
        getContentAnchorEl={null}
      >
        <MenuItem component={RouterLink} to="/app/perfil">
          <ListItemIcon>
            <Icon size={1} path={mdiAccount} />
          </ListItemIcon>
          <ListItemText primary="Perfil" />
        </MenuItem>
        <MenuItem onClick={handleLogout} disabled={isSubmitting}>
          <ListItemIcon>{isSubmitting ? <CircularProgress size={16} style={{ marginLeft: 4 }} /> : <Icon size={1} path={mdiLogout} />}</ListItemIcon>
          <ListItemText primary="Sair" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
