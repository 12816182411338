import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Grid, Button, Collapse, ListItem, makeStyles, Typography, Badge } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Icon from '@mdi/react';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '0px'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    borderRadius: '0px',
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    marginLeft: theme.spacing(4),
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main,
      fontSize: '20px'
    }
  },
  box: {
    width: '100%',
    display: 'flex',
    paddingLeft: '18px'
  },

  listActive: {
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  },

  badge: {
    top: 13,
    right: 12,
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 8,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: '0px 8px',
    color: '#FFFFFF',
    fontSize: '0.75rem',
    backgroundColor: '#d6008f',
    fontWeight: 500
  }
}));

const NavItem = ({ href, icon, depth, title, children, className, info, open: openProp, data, handleCloseMobile, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 8 * depth;
  }

  const style = { paddingLeft };

  const renderExpandebleItem = () => (
    <Button style={style} className={classes.button} onClick={handleToggle} component={Button}>
      <Grid container item justifyContent="space-between" xs={12}>
        <Box className={classes.box}>
          <Grid item container xs={9}>
            {icon && <Icon path={icon} className={classes.icon} size={1} />}
            <Typography variant="body1" color="textPrimary" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end">
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Grid>
        </Box>
      </Grid>
    </Button>
  );

  if (children) {
    return (
      <ListItem key={title} className={clsx(classes.item, className)} disableGutters {...rest}>
        {renderExpandebleItem()}
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem key={title} className={clsx(classes.itemLeaf, className)} disableGutters {...rest}>
      <Button to={href} style={style} onClick={handleCloseMobile} component={RouterLink} className={clsx(classes.buttonLeaf, `depth-${depth}`)} activeClassName={classes.active}>
        <Box className={classes.box}>
          {icon && <Icon path={icon} className={classes.icon} size={1} />}
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
          {info && <Badge classes={{ badge: classes.badge }} badgeContent={info(data)} />}
        </Box>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  open: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.elementType.isRequired,
  depth: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.func,
  children: PropTypes.node,
  active: PropTypes.func.isRequired,
  className: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  handleCloseMobile: PropTypes.func
};

NavItem.defaultProps = {
  open: false,
  className: null,
  info: undefined,
  children: null,
  href: '/',
  handleCloseMobile: () => console.log('eita')
};

export default React.memo(NavItem);
