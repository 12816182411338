import React from 'react';
import Button from '@material-ui/core/Button';

const requestUpdateVersion = ({ code, swVersion, setState }) => {
  const payload = {
    isShowingToast: true,
    toastProps: {
      message: (
        <>
          <strong>Atualização disponível:</strong>
          {' Recarregue a página para obter as últimas atualizações da plataforma.'}
        </>
      ),
      actions: (
        <Button
          onClick={() => {
            window.location.reload(true);
          }}
        >
          Recarregar
        </Button>
      )
    }
  };

  setState(prevState => (swVersion && swVersion !== code ? { ...prevState, ...payload, swVersion: code } : { ...prevState, swVersion: code }));
};

const getManifest = () =>
  fetch('/asset-manifest.json')
    .then(response => response.json())
    .then(data => Object.keys(data).find(key => key.includes('precache-manifest')) || '')
    .catch(e => {
      throw new Error(`Ocorreu um erro na checagem por atualizações: ${e.message}`);
    });

const checkForUpdates = async ({ state = {}, setState }) => {
  const { swVersion } = state;
  const manifest = await getManifest();
  const code = manifest.replace('precache-manifest.', '').replace('.js', '');

  return (
    code &&
    requestUpdateVersion({
      code,
      swVersion,
      setState
    })
  );
};

export const needToCheckForUpdates = process.env.NODE_ENV === 'production';

export default checkForUpdates;
