import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Hidden, Drawer } from '@material-ui/core';
import NavMenu from 'components/Sidebar/NavMenu';

export const MobileDrawer = styled(Drawer)`
  @media print {
    display: none !important;
  }
  .MuiDrawer-paper {
    width: 300px;
    &::-webkit-scrollbar {
      width: 0.4em;
    }
    &::-webkit-scrollbar-track {
      background-color: #ebebeb;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #bdbdbd;
    }
  }
`;

export const DesktopDrawer = styled(Drawer)`
  @media print {
    display: none !important;
  }
  .MuiDrawer-paper {
    padding: 10px 0;
    width: 300px;
    height: auto;
    bottom: 0;
    top: 64px !important;
    > .MuiBox-root {
      height: auto;
    }
    &::-webkit-scrollbar {
      width: 0.4em;
    }
    &::-webkit-scrollbar-track {
      background-color: #ebebeb;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #bdbdbd;
    }
  }
`;

const NavBar = ({ state, handleCloseMobile }) => (
  <>
    <Hidden lgUp>
      <MobileDrawer open={state.isOpenMobile} anchor="left" variant="temporary" onEscapeKeyDown={handleCloseMobile} onBackdropClick={handleCloseMobile}>
        <NavMenu handleCloseMobile={handleCloseMobile} />
      </MobileDrawer>
    </Hidden>
    <Hidden mdDown>
      <DesktopDrawer open={state.isOpenDesktop} anchor="left" variant="persistent">
        <NavMenu />
      </DesktopDrawer>
    </Hidden>
  </>
);

NavBar.propTypes = {
  state: PropTypes.objectOf(PropTypes.object).isRequired,
  handleCloseMobile: PropTypes.func.isRequired
};

export default NavBar;
