import React, { useCallback, useContext, useState } from 'react';

import { Box, Grid, Icon, List, Button, Divider, Popover, ListItem, Typography, ListItemText, ListItemIcon, ListItemAvatar, ButtonBase, LinearProgress } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import useAuth from 'hooks/useAuth';
import Context from 'setup/context';
import SearchInput from 'components/SearchInput';
import AUTH_MATRIX from 'pages/SelectMatrix/authMatrix';
import FETCH_MATRICES from 'pages/SelectMatrix/fetchMatrices';
import Progress from 'components/Progress';

function MapMarkerIcon() {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 9.5C11.3283 9.5 11.6534 9.43534 11.9567 9.3097C12.26 9.18406 12.5356 8.99991 12.7678 8.76777C12.9999 8.53562 13.1841 8.26002 13.3097 7.95671C13.4353 7.65339 13.5 7.3283 13.5 7C13.5 6.33696 13.2366 5.70107 12.7678 5.23223C12.2989 4.76339 11.663 4.5 11 4.5C10.337 4.5 9.70107 4.76339 9.23223 5.23223C8.76339 5.70107 8.5 6.33696 8.5 7C8.5 7.3283 8.56466 7.65339 8.6903 7.95671C8.81594 8.26002 9.00009 8.53562 9.23223 8.76777C9.70107 9.23661 10.337 9.5 11 9.5ZM11 0C14.86 0 18 3.13 18 7C18 12.25 11 20 11 20C11 20 4 12.25 4 7C4 5.14348 4.7375 3.36301 6.05025 2.05025C7.36301 0.737498 9.14348 0 11 0ZM2 7C2 11.5 7.08 17.66 8 18.81L7 20C7 20 0 12.25 0 7C0 3.83 2.11 1.15 5 0.29C3.16 1.94 2 4.33 2 7Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}

export const PlusIcon = styled(Icon)`
  padding-top: 10px;
`;

export const StyledMapMarkerIcon = styled(MapMarkerIcon)`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  justify-content: flex-start;
  width: 100%;
`;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    width: 310px;
  }
`;

export const StlyledImg = styled.img`
  width: 110px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  margin-left: 8px;
`;

export const StlyledInnerImg = styled.img`
  width: 110px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  margin-left: 8px;
`;

export const MatrixSelectorBox = styled(ButtonBase)`
  width: 100%;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 8px !important;
  margin-top: 16px !important;
  display: flex;
  justify-content: space-between !important;
  border-radius: 4px !important;
`;

export const StyledListItem = styled(ListItem)`
  flex-direction: column;
  text-align: center;
`;

const MatrixSelector = () => {
  const { login, matrix } = useAuth();
  const { data, loading } = useQuery(FETCH_MATRICES);
  const [authMatrix] = useMutation(AUTH_MATRIX);

  const [filtering, setFiltering] = useState('');
  const [isSelecting, setSelecting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const matrices = get(data, 'fetchMatrices');

  const filteredMatrices = matrices?.filter(item => item.name.includes(filtering));
  const {
    message: { handleError, handleSuccess }
  } = useContext(Context);

  const handleClose = () => {
    setAnchorEl(null);
    setFiltering('');
  };
  const handleAuthShippingCarrier = useCallback(
    async matrixId => {
      handleClose();
      try {
        setSelecting(true);
        const response = await authMatrix({
          variables: {
            matrix_id: matrixId
          }
        });
        if (response?.data?.authMatrix) {
          await login(response?.data?.authMatrix);
          handleSuccess('Transportadora selecionada com sucesso.');
        }
      } catch (e) {
        handleError(e.message);
      } finally {
        setSelecting(false);
      }
    },
    [authMatrix, handleError, handleSuccess, login]
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpperCaseBeforeSearch = value => {
    setFiltering(value.toUpperCase());
  };

  return (
    <>
      <MatrixSelectorBox display="flex" onClick={handleClick} onClose={handleClose} justifyContent="center">
        {isSelecting ? (
          <Grid container justifyContent="center">
            <Box>
              <Progress size={24} />
            </Box>
          </Grid>
        ) : (
          <StlyledImg src={matrix?.logo_path} with={114} />
        )}
        <Box pt={1}>
          <ArrowDropDownIcon />
        </Box>
      </MatrixSelectorBox>
      <StyledPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <>
          <Grid item xs={12}>
            <Box m={2}>
              <SearchInput value={filtering} onChange={handleUpperCaseBeforeSearch} />
            </Box>
          </Grid>
          <Divider />
          {loading && <LinearProgress />}
          <List>
            {filteredMatrices?.length > 0 ? (
              filteredMatrices.map(item => (
                <StyledListItem key={item.id} button onClick={() => handleAuthShippingCarrier(item?.id)}>
                  <ListItemAvatar>
                    <StlyledInnerImg src={item?.logo_path} alt={item?.name} />
                  </ListItemAvatar>
                  <ListItemText secondary={item?.name} />
                </StyledListItem>
              ))
            ) : (
              <ListItem alignItems="center">
                <Grid container direction="column" alignItems="center">
                  <Typography variant="body2">Nenhum resultado encontrado</Typography>
                </Grid>
              </ListItem>
            )}
          </List>
          <Divider />
          <List>
            <ListItem button disabled>
              <ListItemIcon>
                <Icon>add</Icon>
              </ListItemIcon>
              <ListItemText primary="Cadastrar trasportadora" />
            </ListItem>
          </List>
        </>
      </StyledPopover>
    </>
  );
};

export default MatrixSelector;
