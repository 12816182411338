import { mdiSecurity, mdiAccountTie, mdiAccount } from '@mdi/js';

export const ROLE_USER = 'USER';
export const ROLE_COLLECT = 'COLLECT';
export const ROLE_COMMERCIAL = 'COMMERCIAL';
export const ROLE_TRUSTED = 'TRUSTED';
export const ROLE_ADMIN = 'ADMIN';

export const ROLES = {
  [ROLE_USER]: {
    label: 'Usuário',
    icon: mdiAccount,
    description: 'Tem acesso ao painel para as origens que tem acesso.',
    roles: [ROLE_TRUSTED, ROLE_ADMIN]
  },
  [ROLE_TRUSTED]: {
    label: 'Confiável',
    icon: mdiSecurity,
    description: 'Tem acesso completa ao painel e pode convidar outros usuários para as origens que tem acesso.',
    roles: [ROLE_TRUSTED, ROLE_ADMIN]
  },
  [ROLE_ADMIN]: {
    label: 'Administrador',
    icon: mdiAccountTie,
    description: 'Pode executar todas ações em todas as origens cadastradas para a transportadora.',
    roles: [ROLE_ADMIN]
  }
};

export const ROLES_ARRAY = [
  {
    value: ROLE_USER,
    label: ROLES[ROLE_USER].label
  },
  {
    value: ROLE_TRUSTED,
    label: ROLES[ROLE_TRUSTED].label
  },
  {
    value: ROLE_ADMIN,
    label: ROLES[ROLE_ADMIN].label
  }
];
