import React, { createContext, useEffect, useCallback, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import { PropTypes } from 'prop-types';
import SplashScreen from 'components/SplashScreen';
import apolloClient from 'setup/apolloClient';
import { useQuery, useMutation } from '@apollo/client';
import { LOCALSTORAGE_AUTH, LOCALSTORAGE_TOKEN } from 'setup/apolloClient/constants';
import LOGOUT from './logout';
import CHECK_MATRIX_STATUS from './checkMatrixStatus';

const initialAuthState = {
  user: null,
  member: null,
  matrix: null,
  shipping_carrier: null,
  isSessionStorage: false,
  isInitialised: false,
  isAuthenticated: false,
  collects: null,
  collects_shipping_carrier_count: null,
  tickets_count: null
};

const setToken = (token, isSessionStorage) => {
  if (isSessionStorage) {
    window.sessionStorage.setItem(LOCALSTORAGE_TOKEN, token);
  } else {
    window.localStorage.setItem(LOCALSTORAGE_TOKEN, token);
  }
};

const setAuthData = (data, isSessionStorage) => {
  const stringfied = JSON.stringify(data);
  if (isSessionStorage) {
    window.sessionStorage.setItem(LOCALSTORAGE_AUTH, stringfied);
  } else {
    window.localStorage.setItem(LOCALSTORAGE_AUTH, stringfied);
  }
};

export const removeTokens = isSessionStorage => {
  if (isSessionStorage) {
    sessionStorage.removeItem(LOCALSTORAGE_TOKEN);
    sessionStorage.removeItem(LOCALSTORAGE_AUTH);
  } else {
    localStorage.removeItem(LOCALSTORAGE_TOKEN);
    localStorage.removeItem(LOCALSTORAGE_AUTH);
  }
  apolloClient.resetStore();
};

export const getInitialToken = () => {
  const hasSession = window.sessionStorage.getItem(LOCALSTORAGE_TOKEN);
  const { token } = queryString.parse(window.location.search);
  if (token) {
    const { user, member, matrix, shipping_carrier } = jwtDecode(token);
    const authData = { user, member, matrix, shipping_carrier };
    setToken(token, true);
    setAuthData(authData, true);
    return {
      token,
      authData,
      isSessionStorage: true
    };
  } else if (hasSession) {
    return {
      token: window.sessionStorage.getItem(LOCALSTORAGE_TOKEN),
      authData: JSON.parse(window.sessionStorage.getItem(LOCALSTORAGE_AUTH)),
      isSessionStorage: true
    };
  } else {
    return {
      token: window.localStorage.getItem(LOCALSTORAGE_TOKEN),
      authData: JSON.parse(window.localStorage.getItem(LOCALSTORAGE_AUTH)),
      isSessionStorage: false
    };
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, isSessionStorage, user, matrix, member, shipping_carrier } = action.payload;
      return {
        ...state,
        user,
        matrix,
        member,
        shipping_carrier,
        isInitialised: true,
        isSessionStorage,
        isAuthenticated
      };
    }
    case 'LOGIN': {
      const { user, matrix, member, shipping_carrier } = action.payload;
      return {
        ...state,
        user,
        member,
        matrix,
        shipping_carrier,
        isSessionStorage: false,
        isAuthenticated: true
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        user: null,
        member: null,
        matrix: null,
        shipping_carrier: null,
        isSessionStorage: false,
        isAuthenticated: false
      };
    }
    case 'SET_SHIPPING_CARRIER': {
      const { shipping_carrier } = action.payload;
      return {
        ...state,
        shipping_carrier
      };
    }
    case 'CHECK_MATRIX': {
      const { matrix, member, user, collects, overdue_collects, tickets_count } = action.payload;
      return {
        ...state,
        user,
        member,
        matrix,
        collects,
        overdue_collects,
        tickets_count
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => {},
  setShippingCarrier: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();
  const [doLogout] = useMutation(LOGOUT);
  const { refetch } = useQuery(CHECK_MATRIX_STATUS, {
    skip: true,
    fetchPolicy: 'network-only'
  });

  const checkMatrixStatus = useCallback(
    async ({ shipping_carrier }) => {
      if (shipping_carrier) {
        const response = await refetch({
          shipping_carrier_id: shipping_carrier.id
        });
        if (response?.data) {
          dispatch({
            type: 'CHECK_MATRIX',
            payload: response.data.checkMatrixStatus
          });
          setAuthData(
            {
              user: response.data.checkMatrixStatus.user,
              member: response.data.checkMatrixStatus.member,
              matrix: response.data.checkMatrixStatus.matrix,
              shipping_carrier: response.data.checkMatrixStatus.shipping_carrier
            },
            state.isSessionStorage
          );
        }
      }
    },
    [refetch, state.isSessionStorage]
  );

  const login = async data => {
    const { token } = data;
    const decode = jwtDecode(token);
    const { user, member, matrix, shipping_carrier } = decode;

    setToken(token);
    setAuthData({
      user,
      member,
      matrix,
      shipping_carrier
    });

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        member,
        matrix,
        shipping_carrier
      }
    });
  };

  const setShippingCarrier = shipping_carrier => {
    dispatch({
      type: 'SET_SHIPPING_CARRIER',
      payload: {
        shipping_carrier
      }
    });
  };

  const logout = async () => {
    try {
      await doLogout();
    } catch (error) {
      console.log('error', error);
    } finally {
      dispatch({ type: 'LOGOUT' });
      removeTokens(true);
      removeTokens();
      history.push('/');
    }
  };

  useEffect(() => {
    const initialise = async () => {
      const { token, authData, isSessionStorage } = getInitialToken();
      if (token && authData) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            ...authData,
            isSessionStorage,
            isAuthenticated: true
          }
        });
      } else {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isSessionStorage: false,
            isAuthenticated: false
          }
        });
      }
    };
    initialise();
  }, []);

  const shipping_carrier = useMemo(() => state.shipping_carrier, [state.shipping_carrier]);
  const handleCheckMatrixStatus = useCallback(prop => checkMatrixStatus(prop), [checkMatrixStatus]);

  useEffect(() => {
    const FIVE_MINUTES = 300000;

    const onCheckMatrixStatus = () => {
      handleCheckMatrixStatus({ shipping_carrier });
    };

    onCheckMatrixStatus();
    const interval = setInterval(() => onCheckMatrixStatus(), FIVE_MINUTES);

    return () => {
      clearInterval(interval);
    };
  }, [shipping_carrier, handleCheckMatrixStatus]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        setShippingCarrier,
        checkMatrixStatus
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContext.propTypes = {
  children: PropTypes.node
};

export default AuthContext;
