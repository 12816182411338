import React, { useRef, useState } from 'react';
import { sumBy, filter } from 'lodash';
import { Menu, Badge, Avatar, MenuItem, ListItemIcon, ListItemText, IconButton, LinearProgress, MenuList, Divider, Typography } from '@material-ui/core';
import { mdiBell } from '@mdi/js';
import Icon from '@mdi/react';
import { useHistory } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useShippingCarriers from 'hooks/useShippingCarriers';
import { TERTIARY_COLOR } from 'setup/colors';

const Collects = () => {
  const ref = useRef(null);
  const { collects, overdue_collects, setShippingCarrier } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const { shipping_carriers, loading } = useShippingCarriers();

  const collects_count = React.useMemo(() => sumBy(collects, collect => collect.total), [collects]);
  const overdue_collects_count = React.useMemo(() => sumBy(overdue_collects, collect => collect.total), [overdue_collects]);

  const collectsByCarrier = React.useMemo(
    () =>
      collects
        ? collects.reduce(
            (obj, item) => ({
              ...obj,
              [item.shipping_carrier_id]: item.total
            }),
            {}
          )
        : [],
    [collects]
  );

  const overdueCollectsByCarrier = React.useMemo(
    () =>
      overdue_collects
        ? overdue_collects.reduce(
            (obj, item) => ({
              ...obj,
              [item.shipping_carrier_id]: item.total
            }),
            {}
          )
        : [],
    [overdue_collects]
  );

  const originsFiltered = React.useMemo(() => filter(shipping_carriers, origin => collectsByCarrier[origin.id]), [shipping_carriers, collectsByCarrier]);

  const overdueOriginsFiltered = React.useMemo(() => filter(shipping_carriers, origin => overdueCollectsByCarrier[origin.id]), [shipping_carriers, overdueCollectsByCarrier]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOrigin = origin => {
    setShippingCarrier(origin);
    handleClose();
    if (history.location.pathname !== '/app/coletas/novas') {
      history.push('/app/coletas/novas');
    }
  };

  const handleOverdueOrigin = origin => {
    setShippingCarrier(origin);
    handleClose();
    if (history.location.pathname !== '/app/coletas-atrasadas') {
      history.push('/app/coletas-atrasadas');
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={handleOpen} disabled={collects_count + overdue_collects_count === 0}>
        <Badge badgeContent={collects_count + overdue_collects_count} color="error">
          <Icon size={1} path={mdiBell} color="white" />
        </Badge>
      </IconButton>
      <Menu
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        anchorEl={ref.current}
        keepMounted
        getContentAnchorEl={null}
      >
        {loading && <LinearProgress />}
        <MenuList>
          <MenuItem disabled>
            <Typography variant="caption" color="textPrimary">
              NOVAS SOLICITAÇÕES
            </Typography>
          </MenuItem>
          {originsFiltered.map(origin => (
            <MenuItem onClick={() => handleOrigin(origin)}>
              <ListItemIcon style={{ minWidth: 44 }}>
                <Avatar style={{ backgroundColor: TERTIARY_COLOR, fontSize: 14, width: 32, height: 32 }}>{collectsByCarrier[origin.id]}</Avatar>
              </ListItemIcon>
              <ListItemText primary={origin.name} />
            </MenuItem>
          ))}
          {originsFiltered.length === 0 && (
            <MenuItem disabled>
              <Typography variant="caption" color="textPrimary">
                Não há novas solicitações de coleta.
              </Typography>
            </MenuItem>
          )}
        </MenuList>
        <Divider />
        <MenuList>
          <MenuItem disabled>
            <Typography variant="caption" color="textPrimary">
              COLETAS ATRASADAS
            </Typography>
          </MenuItem>
          {overdueOriginsFiltered.map(origin => (
            <MenuItem onClick={() => handleOverdueOrigin(origin, true)}>
              <ListItemIcon style={{ minWidth: 44 }}>
                <Avatar style={{ backgroundColor: TERTIARY_COLOR, fontSize: 14, width: 32, height: 32 }}>{overdueCollectsByCarrier[origin.id]}</Avatar>
              </ListItemIcon>
              <ListItemText primary={origin.name} />
            </MenuItem>
          ))}
          {overdueOriginsFiltered.length === 0 && (
            <MenuItem disabled>
              <Typography variant="caption" color="textPrimary">
                Não há coletas atrasadas.
              </Typography>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default Collects;
